<template>
    <section class="c-404">
        <div class="c-404__body">

            <app-intro>
                <h1>404</h1>
                <h2>Pagina niet gevonden</h2>
            </app-intro>

        </div>
    </section>
</template>

<script>
    import AppIntro from '../components/app/AppIntro.vue';

    export default {
        components: { AppIntro },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .c-404 {
        display: grid;
        height: 100%;

        &__body {
            align-self: center;
            justify-self: center;
            width: 50%;
        }
    }
</style>
